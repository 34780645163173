/* tslint:disable */
/* eslint-disable */
/**
 * ppe.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    'placeholder'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    'job_code'?: string;
}
/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject1
     */
    'trackable_item_uuid'?: string;
}
/**
 * 
 * @export
 * @interface InlineObject2
 */
export interface InlineObject2 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject2
     */
    'trackable_item_uuid'?: string;
}
/**
 * 
 * @export
 * @interface InlineObject3
 */
export interface InlineObject3 {
    /**
     * 
     * @type {boolean}
     * @memberof InlineObject3
     */
    'auto_approval'?: boolean;
}
/**
 * 
 * @export
 * @interface InlineObject4
 */
export interface InlineObject4 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject4
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface InlineObject5
 */
export interface InlineObject5 {
    /**
     * due to the uuid is used as a path parameter, the request body is actually empty, which will cause api request error on dart code the placeholder is used to force the code generator to generate request body for dart code.
     * @type {string}
     * @memberof InlineObject5
     */
    'placeholder'?: string;
}
/**
 * 
 * @export
 * @interface InlineObject6
 */
export interface InlineObject6 {
    /**
     * due to the uuid is used as a path parameter, the request body is actually empty, which will cause api request error on dart code the placeholder is used to force the code generator to generate request body for dart code.
     * @type {string}
     * @memberof InlineObject6
     */
    'placeholder'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject6
     */
    'job_code'?: string;
}
/**
 * 
 * @export
 * @interface PpeEmployeeClaimTrackableItemResponse
 */
export interface PpeEmployeeClaimTrackableItemResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeClaimTrackableItemResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeClaimTrackableItemResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PpeEmployeeCodeType = {
    VendingMachine: 'VENDING_MACHINE',
    Locker: 'LOCKER',
    TrackableItem: 'TRACKABLE_ITEM'
} as const;

export type PpeEmployeeCodeType = typeof PpeEmployeeCodeType[keyof typeof PpeEmployeeCodeType];


/**
 * 
 * @export
 * @interface PpeEmployeeConfirmOrderResponse
 */
export interface PpeEmployeeConfirmOrderResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeConfirmOrderResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeConfirmOrderResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeEmployeeConfirmReturnItemToLockerResponse
 */
export interface PpeEmployeeConfirmReturnItemToLockerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeConfirmReturnItemToLockerResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeConfirmReturnItemToLockerResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeEmployeeExchangeSessionTokenWithGoogleAccessTokenRequest
 */
export interface PpeEmployeeExchangeSessionTokenWithGoogleAccessTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeExchangeSessionTokenWithGoogleAccessTokenRequest
     */
    'token'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeExchangeSessionTokenWithGoogleAccessTokenRequest
     */
    'access_token'?: string;
    /**
     * 
     * @type {PpeEmployeeGoogleSsoDeviceType}
     * @memberof PpeEmployeeExchangeSessionTokenWithGoogleAccessTokenRequest
     */
    'device_type'?: PpeEmployeeGoogleSsoDeviceType;
}
/**
 * 
 * @export
 * @interface PpeEmployeeExchangeSessionTokenWithGoogleAccessTokenResponse
 */
export interface PpeEmployeeExchangeSessionTokenWithGoogleAccessTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeExchangeSessionTokenWithGoogleAccessTokenResponse
     */
    'session_token'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeExchangeSessionTokenWithGoogleAccessTokenResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeExchangeSessionTokenWithGoogleAccessTokenResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeEmployeeExchangeSessionTokenWithMicrosoftAccessTokenRequest
 */
export interface PpeEmployeeExchangeSessionTokenWithMicrosoftAccessTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeExchangeSessionTokenWithMicrosoftAccessTokenRequest
     */
    'access_token'?: string;
}
/**
 * 
 * @export
 * @interface PpeEmployeeExchangeSessionTokenWithMicrosoftAccessTokenResponse
 */
export interface PpeEmployeeExchangeSessionTokenWithMicrosoftAccessTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeExchangeSessionTokenWithMicrosoftAccessTokenResponse
     */
    'session_token'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeExchangeSessionTokenWithMicrosoftAccessTokenResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeExchangeSessionTokenWithMicrosoftAccessTokenResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeEmployeeExchangeSessionTokenWithTemporaryAccessTokenRequest
 */
export interface PpeEmployeeExchangeSessionTokenWithTemporaryAccessTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeExchangeSessionTokenWithTemporaryAccessTokenRequest
     */
    'temporary_access_token'?: string;
}
/**
 * 
 * @export
 * @interface PpeEmployeeExchangeSessionTokenWithTemporaryAccessTokenResponse
 */
export interface PpeEmployeeExchangeSessionTokenWithTemporaryAccessTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeExchangeSessionTokenWithTemporaryAccessTokenResponse
     */
    'token'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeExchangeSessionTokenWithTemporaryAccessTokenResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeExchangeSessionTokenWithTemporaryAccessTokenResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeEmployeeGetAllVendingMachinesResponse
 */
export interface PpeEmployeeGetAllVendingMachinesResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeGetAllVendingMachinesResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeGetAllVendingMachinesResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {Array<PpeEmployeeVendingMachine>}
     * @memberof PpeEmployeeGetAllVendingMachinesResponse
     */
    'vending_machine'?: Array<PpeEmployeeVendingMachine>;
}
/**
 * 
 * @export
 * @interface PpeEmployeeGetDetailResponse
 */
export interface PpeEmployeeGetDetailResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeGetDetailResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeGetDetailResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {PpeEmployeeUserDetail}
     * @memberof PpeEmployeeGetDetailResponse
     */
    'user_detail'?: PpeEmployeeUserDetail;
}
/**
 * 
 * @export
 * @interface PpeEmployeeGetGoogleSsoConfigResponse
 */
export interface PpeEmployeeGetGoogleSsoConfigResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeGetGoogleSsoConfigResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeGetGoogleSsoConfigResponse
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeGetGoogleSsoConfigResponse
     */
    'client_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeGetGoogleSsoConfigResponse
     */
    'android_client_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeGetGoogleSsoConfigResponse
     */
    'ios_client_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeGetGoogleSsoConfigResponse
     */
    'redirect_uri'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeGetGoogleSsoConfigResponse
     */
    'android_redirect_uri'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeGetGoogleSsoConfigResponse
     */
    'ios_redirect_uri'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeGetGoogleSsoConfigResponse
     */
    'url_authorize'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeGetGoogleSsoConfigResponse
     */
    'url_access_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeGetGoogleSsoConfigResponse
     */
    'scopes'?: string;
}
/**
 * 
 * @export
 * @interface PpeEmployeeGetListOfTrackableItemCurrentInUseByMeResponse
 */
export interface PpeEmployeeGetListOfTrackableItemCurrentInUseByMeResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeGetListOfTrackableItemCurrentInUseByMeResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeGetListOfTrackableItemCurrentInUseByMeResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {Array<PpeEmployeeTrackableItem>}
     * @memberof PpeEmployeeGetListOfTrackableItemCurrentInUseByMeResponse
     */
    'trackable_items'?: Array<PpeEmployeeTrackableItem>;
}
/**
 * 
 * @export
 * @interface PpeEmployeeGetLockerCellResponse
 */
export interface PpeEmployeeGetLockerCellResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeGetLockerCellResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeGetLockerCellResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {PpeEmployeeLockerCell}
     * @memberof PpeEmployeeGetLockerCellResponse
     */
    'cell'?: PpeEmployeeLockerCell;
}
/**
 * 
 * @export
 * @interface PpeEmployeeGetLockerInformationResponse
 */
export interface PpeEmployeeGetLockerInformationResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeGetLockerInformationResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeGetLockerInformationResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {Array<PpeEmployeeLockerCell>}
     * @memberof PpeEmployeeGetLockerInformationResponse
     */
    'cells'?: Array<PpeEmployeeLockerCell>;
}
/**
 * 
 * @export
 * @interface PpeEmployeeGetLogoResponse
 */
export interface PpeEmployeeGetLogoResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeGetLogoResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeGetLogoResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeGetLogoResponse
     */
    'img'?: string;
}
/**
 * 
 * @export
 * @interface PpeEmployeeGetMicrosoftSsoConfigResponse
 */
export interface PpeEmployeeGetMicrosoftSsoConfigResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeGetMicrosoftSsoConfigResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeGetMicrosoftSsoConfigResponse
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeGetMicrosoftSsoConfigResponse
     */
    'client_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeGetMicrosoftSsoConfigResponse
     */
    'redirect_uri'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeGetMicrosoftSsoConfigResponse
     */
    'redirect_uri_for_employee'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeGetMicrosoftSsoConfigResponse
     */
    'redirect_uri_for_employee_android_app'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeGetMicrosoftSsoConfigResponse
     */
    'redirect_uri_for_employee_ios_app'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeGetMicrosoftSsoConfigResponse
     */
    'url_authorize'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeGetMicrosoftSsoConfigResponse
     */
    'url_access_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeGetMicrosoftSsoConfigResponse
     */
    'scopes'?: string;
}
/**
 * 
 * @export
 * @interface PpeEmployeeGetOrderStatusResponse
 */
export interface PpeEmployeeGetOrderStatusResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeGetOrderStatusResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeGetOrderStatusResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {PpeEmployeeOrder}
     * @memberof PpeEmployeeGetOrderStatusResponse
     */
    'order'?: PpeEmployeeOrder;
}
/**
 * 
 * @export
 * @interface PpeEmployeeGetOrganizationDetailResponse
 */
export interface PpeEmployeeGetOrganizationDetailResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeGetOrganizationDetailResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeGetOrganizationDetailResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {PpeEmployeeOrganizationDetail}
     * @memberof PpeEmployeeGetOrganizationDetailResponse
     */
    'organization_detail'?: PpeEmployeeOrganizationDetail;
}
/**
 * 
 * @export
 * @interface PpeEmployeeGetSessionRequest
 */
export interface PpeEmployeeGetSessionRequest {
    /**
     * 
     * @type {PpeEmployeeSessionSource}
     * @memberof PpeEmployeeGetSessionRequest
     */
    'source'?: PpeEmployeeSessionSource;
    /**
     * 
     * @type {number}
     * @memberof PpeEmployeeGetSessionRequest
     */
    'per_page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeEmployeeGetSessionRequest
     */
    'page'?: number;
}
/**
 * 
 * @export
 * @interface PpeEmployeeGetSessionsResponse
 */
export interface PpeEmployeeGetSessionsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeGetSessionsResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeGetSessionsResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {PpeEmployeeSessionPagination}
     * @memberof PpeEmployeeGetSessionsResponse
     */
    'data'?: PpeEmployeeSessionPagination;
}
/**
 * 
 * @export
 * @interface PpeEmployeeGetTransactionsCsvRequest
 */
export interface PpeEmployeeGetTransactionsCsvRequest {
    /**
     * 
     * @type {number}
     * @memberof PpeEmployeeGetTransactionsCsvRequest
     */
    'from_epoch'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeEmployeeGetTransactionsCsvRequest
     */
    'to_epoch'?: number;
}
/**
 * 
 * @export
 * @interface PpeEmployeeGetTransactionsCsvResponse
 */
export interface PpeEmployeeGetTransactionsCsvResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeGetTransactionsCsvResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeGetTransactionsCsvResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {Array<PpeEmployeeTransactionCsv>}
     * @memberof PpeEmployeeGetTransactionsCsvResponse
     */
    'data'?: Array<PpeEmployeeTransactionCsv>;
}
/**
 * 
 * @export
 * @interface PpeEmployeeGetTransactionsResponse
 */
export interface PpeEmployeeGetTransactionsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeGetTransactionsResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeGetTransactionsResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {PpeEmployeeTransactionPagination}
     * @memberof PpeEmployeeGetTransactionsResponse
     */
    'data'?: PpeEmployeeTransactionPagination;
}
/**
 * 
 * @export
 * @interface PpeEmployeeGetTransactionsResponseV2
 */
export interface PpeEmployeeGetTransactionsResponseV2 {
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeGetTransactionsResponseV2
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeGetTransactionsResponseV2
     */
    'error_message'?: string;
    /**
     * 
     * @type {PpeEmployeeTransactionPaginationV2}
     * @memberof PpeEmployeeGetTransactionsResponseV2
     */
    'data'?: PpeEmployeeTransactionPaginationV2;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PpeEmployeeGoogleSsoDeviceType = {
    Android: 'ANDROID',
    Ios: 'IOS',
    Website: 'WEBSITE'
} as const;

export type PpeEmployeeGoogleSsoDeviceType = typeof PpeEmployeeGoogleSsoDeviceType[keyof typeof PpeEmployeeGoogleSsoDeviceType];


/**
 * 
 * @export
 * @interface PpeEmployeeItemInCell
 */
export interface PpeEmployeeItemInCell {
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeItemInCell
     */
    'is_trackable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeItemInCell
     */
    'is_available'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeItemInCell
     */
    'item_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeItemInCell
     */
    'photo_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeItemInCell
     */
    'asset_tag'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeItemInCell
     */
    'organization_name'?: string;
}
/**
 * Location describes where an item can be. It could be vending machine, or text description. It\'s reserved for now.
 * @export
 * @interface PpeEmployeeItemLocation
 */
export interface PpeEmployeeItemLocation {
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeItemLocation
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface PpeEmployeeJob
 */
export interface PpeEmployeeJob {
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeJob
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeJob
     */
    'job_code'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PpeEmployeeJobIDStatus = {
    Disabled: 'JOB_ID_DISABLED',
    Enabled: 'JOB_ID_ENABLED',
    Enforced: 'JOB_ID_ENFORCED',
    StrictlyEnforced: 'JOB_ID_STRICTLY_ENFORCED'
} as const;

export type PpeEmployeeJobIDStatus = typeof PpeEmployeeJobIDStatus[keyof typeof PpeEmployeeJobIDStatus];


/**
 * 
 * @export
 * @interface PpeEmployeeKioskLoginRequest
 */
export interface PpeEmployeeKioskLoginRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeKioskLoginRequest
     */
    'access_code'?: string;
}
/**
 * 
 * @export
 * @interface PpeEmployeeKioskLoginResponse
 */
export interface PpeEmployeeKioskLoginResponse {
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeKioskLoginResponse
     */
    'token'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeKioskLoginResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeKioskLoginResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeEmployeeListJobsResponse
 */
export interface PpeEmployeeListJobsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeListJobsResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeListJobsResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {Array<PpeEmployeeJob>}
     * @memberof PpeEmployeeListJobsResponse
     */
    'jobs'?: Array<PpeEmployeeJob>;
}
/**
 * 
 * @export
 * @interface PpeEmployeeListLockersResponse
 */
export interface PpeEmployeeListLockersResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeListLockersResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeListLockersResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {Array<PpeEmployeeLocker>}
     * @memberof PpeEmployeeListLockersResponse
     */
    'lockers'?: Array<PpeEmployeeLocker>;
}
/**
 * 
 * @export
 * @interface PpeEmployeeListTrackableItemsResponse
 */
export interface PpeEmployeeListTrackableItemsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeListTrackableItemsResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeListTrackableItemsResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {Array<PpeEmployeeTrackableItem>}
     * @memberof PpeEmployeeListTrackableItemsResponse
     */
    'trackable_items'?: Array<PpeEmployeeTrackableItem>;
}
/**
 * 
 * @export
 * @interface PpeEmployeeLocker
 */
export interface PpeEmployeeLocker {
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeLocker
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeLocker
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeLocker
     */
    'site'?: string;
    /**
     * 
     * @type {number}
     * @memberof PpeEmployeeLocker
     */
    'rows'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeEmployeeLocker
     */
    'cols'?: number;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeLocker
     */
    'photo_url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeLocker
     */
    'online'?: boolean;
}
/**
 * 
 * @export
 * @interface PpeEmployeeLockerCell
 */
export interface PpeEmployeeLockerCell {
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeLockerCell
     */
    'uuid'?: string;
    /**
     * 
     * @type {number}
     * @memberof PpeEmployeeLockerCell
     */
    'row'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeEmployeeLockerCell
     */
    'col'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeLockerCell
     */
    'is_occupied'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeLockerCell
     */
    'product_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeLockerCell
     */
    'photo_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeLockerCell
     */
    'locker_site'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeLockerCell
     */
    'locker_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeLockerCell
     */
    'organization_name'?: string;
    /**
     * 
     * @type {PpeEmployeeItemInCell}
     * @memberof PpeEmployeeLockerCell
     */
    'item'?: PpeEmployeeItemInCell;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeLockerCell
     */
    'display_name'?: string;
}
/**
 * 
 * @export
 * @interface PpeEmployeeLockerPickupInformation
 */
export interface PpeEmployeeLockerPickupInformation {
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeLockerPickupInformation
     */
    'is_trackable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PpeEmployeeLockerPickupInformation
     */
    'cell_row'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeEmployeeLockerPickupInformation
     */
    'cell_col'?: number;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeLockerPickupInformation
     */
    'cell_display_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeLockerPickupInformation
     */
    'item_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeLockerPickupInformation
     */
    'locker_site'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeLockerPickupInformation
     */
    'locker_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeLockerPickupInformation
     */
    'organization_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeLockerPickupInformation
     */
    'asset_tag'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeLockerPickupInformation
     */
    'trackable_item_uuid'?: string;
}
/**
 * 
 * @export
 * @interface PpeEmployeeOpenCellDoorToReturnResponse
 */
export interface PpeEmployeeOpenCellDoorToReturnResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeOpenCellDoorToReturnResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeOpenCellDoorToReturnResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeEmployeeOrder
 */
export interface PpeEmployeeOrder {
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeOrder
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeOrder
     */
    'created_at_epoch'?: string;
    /**
     * 
     * @type {PpeEmployeeOrderState}
     * @memberof PpeEmployeeOrder
     */
    'state'?: PpeEmployeeOrderState;
    /**
     * 
     * @type {PpeEmployeeOrderSelection}
     * @memberof PpeEmployeeOrder
     */
    'selection'?: PpeEmployeeOrderSelection;
    /**
     * 
     * @type {PpeEmployeeOrderVendingMachine}
     * @memberof PpeEmployeeOrder
     */
    'vending_machine'?: PpeEmployeeOrderVendingMachine;
}
/**
 * 
 * @export
 * @interface PpeEmployeeOrderSelection
 */
export interface PpeEmployeeOrderSelection {
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeOrderSelection
     */
    'selection_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeOrderSelection
     */
    'product_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeOrderSelection
     */
    'photo_url'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PpeEmployeeOrderState = {
    PendingSelection: 'PENDING_SELECTION',
    PendingCustomerConfirm: 'PENDING_CUSTOMER_CONFIRM',
    Vending: 'VENDING',
    Failure: 'FAILURE',
    VendedSuccess: 'VENDED_SUCCESS',
    Aborted: 'ABORTED'
} as const;

export type PpeEmployeeOrderState = typeof PpeEmployeeOrderState[keyof typeof PpeEmployeeOrderState];


/**
 * 
 * @export
 * @interface PpeEmployeeOrderVendingMachine
 */
export interface PpeEmployeeOrderVendingMachine {
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeOrderVendingMachine
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeOrderVendingMachine
     */
    'site'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeOrderVendingMachine
     */
    'organization_name'?: string;
}
/**
 * 
 * @export
 * @interface PpeEmployeeOrganizationDetail
 */
export interface PpeEmployeeOrganizationDetail {
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeOrganizationDetail
     */
    'phone_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeOrganizationDetail
     */
    'phone_number_display'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeOrganizationDetail
     */
    'organization_name'?: string;
    /**
     * 
     * @type {PpeEmployeeJobIDStatus}
     * @memberof PpeEmployeeOrganizationDetail
     */
    'job_id_status'?: PpeEmployeeJobIDStatus;
}
/**
 * 
 * @export
 * @interface PpeEmployeePickupItemFromLockerResponse
 */
export interface PpeEmployeePickupItemFromLockerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeePickupItemFromLockerResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeePickupItemFromLockerResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {PpeEmployeeLockerPickupInformation}
     * @memberof PpeEmployeePickupItemFromLockerResponse
     */
    'pickup_information'?: PpeEmployeeLockerPickupInformation;
}
/**
 * 
 * @export
 * @interface PpeEmployeePostAuthenticationRequest
 */
export interface PpeEmployeePostAuthenticationRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeePostAuthenticationRequest
     */
    'email_address'?: string;
}
/**
 * 
 * @export
 * @interface PpeEmployeePostAuthenticationResponse
 */
export interface PpeEmployeePostAuthenticationResponse {
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeePostAuthenticationResponse
     */
    'verification_id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeePostAuthenticationResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeePostAuthenticationResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeEmployeePostFcmTokenRequest
 */
export interface PpeEmployeePostFcmTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeePostFcmTokenRequest
     */
    'fcm_token'?: string;
}
/**
 * 
 * @export
 * @interface PpeEmployeePostFcmTokenResponse
 */
export interface PpeEmployeePostFcmTokenResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeePostFcmTokenResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeePostFcmTokenResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeEmployeePostVerificationRequest
 */
export interface PpeEmployeePostVerificationRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeePostVerificationRequest
     */
    'auth_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeePostVerificationRequest
     */
    'verification_id'?: string;
}
/**
 * 
 * @export
 * @interface PpeEmployeePostVerificationResponse
 */
export interface PpeEmployeePostVerificationResponse {
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeePostVerificationResponse
     */
    'token'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeePostVerificationResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeePostVerificationResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeEmployeeReportTrackableItemRequiresInspectionResponse
 */
export interface PpeEmployeeReportTrackableItemRequiresInspectionResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeReportTrackableItemRequiresInspectionResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeReportTrackableItemRequiresInspectionResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeEmployeeReturnInformation
 */
export interface PpeEmployeeReturnInformation {
    /**
     * 
     * @type {PpeEmployeeLocker}
     * @memberof PpeEmployeeReturnInformation
     */
    'locker'?: PpeEmployeeLocker;
    /**
     * 
     * @type {PpeEmployeeLockerCell}
     * @memberof PpeEmployeeReturnInformation
     */
    'cell'?: PpeEmployeeLockerCell;
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeReturnInformation
     */
    'is_return_to_locker'?: boolean;
}
/**
 * 
 * @export
 * @interface PpeEmployeeReturnTrackableItemResponse
 */
export interface PpeEmployeeReturnTrackableItemResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeReturnTrackableItemResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeReturnTrackableItemResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeEmployeeSearchByScannedCodeRequest
 */
export interface PpeEmployeeSearchByScannedCodeRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeSearchByScannedCodeRequest
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @interface PpeEmployeeSearchByScannedCodeResponse
 */
export interface PpeEmployeeSearchByScannedCodeResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeSearchByScannedCodeResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeSearchByScannedCodeResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {PpeEmployeeCodeType}
     * @memberof PpeEmployeeSearchByScannedCodeResponse
     */
    'type'?: PpeEmployeeCodeType;
    /**
     * 
     * @type {PpeEmployeeTrackableItem}
     * @memberof PpeEmployeeSearchByScannedCodeResponse
     */
    'trackable_item'?: PpeEmployeeTrackableItem;
    /**
     * 
     * @type {PpeEmployeeVendingMachine}
     * @memberof PpeEmployeeSearchByScannedCodeResponse
     */
    'vending_machine'?: PpeEmployeeVendingMachine;
    /**
     * 
     * @type {PpeEmployeeLocker}
     * @memberof PpeEmployeeSearchByScannedCodeResponse
     */
    'locker'?: PpeEmployeeLocker;
}
/**
 * 
 * @export
 * @interface PpeEmployeeSearchTrackableItemRequest
 */
export interface PpeEmployeeSearchTrackableItemRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeSearchTrackableItemRequest
     */
    'asset_tag'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeSearchTrackableItemRequest
     */
    'uuid'?: string;
}
/**
 * 
 * @export
 * @interface PpeEmployeeSearchTrackableItemResponse
 */
export interface PpeEmployeeSearchTrackableItemResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeSearchTrackableItemResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeSearchTrackableItemResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {PpeEmployeeTrackableItem}
     * @memberof PpeEmployeeSearchTrackableItemResponse
     */
    'trackable_item'?: PpeEmployeeTrackableItem;
}
/**
 * 
 * @export
 * @interface PpeEmployeeSearchVendingMachineRequest
 */
export interface PpeEmployeeSearchVendingMachineRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeSearchVendingMachineRequest
     */
    'qr_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeSearchVendingMachineRequest
     */
    'uuid'?: string;
}
/**
 * 
 * @export
 * @interface PpeEmployeeSearchVendingMachineResponse
 */
export interface PpeEmployeeSearchVendingMachineResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeSearchVendingMachineResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeSearchVendingMachineResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {PpeEmployeeVendingMachine}
     * @memberof PpeEmployeeSearchVendingMachineResponse
     */
    'vending_machine'?: PpeEmployeeVendingMachine;
}
/**
 * 
 * @export
 * @interface PpeEmployeeSession
 */
export interface PpeEmployeeSession {
    /**
     * 
     * @type {number}
     * @memberof PpeEmployeeSession
     */
    'created_at_epoch'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeEmployeeSession
     */
    'last_access_at_epoch'?: number;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeSession
     */
    'user_agent'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeSession
     */
    'ip'?: string;
    /**
     * 
     * @type {PpeEmployeeSessionSource}
     * @memberof PpeEmployeeSession
     */
    'source'?: PpeEmployeeSessionSource;
}
/**
 * 
 * @export
 * @interface PpeEmployeeSessionPagination
 */
export interface PpeEmployeeSessionPagination {
    /**
     * 
     * @type {number}
     * @memberof PpeEmployeeSessionPagination
     */
    'current_page'?: number;
    /**
     * 
     * @type {Array<PpeEmployeeSession>}
     * @memberof PpeEmployeeSessionPagination
     */
    'sessions'?: Array<PpeEmployeeSession>;
    /**
     * 
     * @type {number}
     * @memberof PpeEmployeeSessionPagination
     */
    'from'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeEmployeeSessionPagination
     */
    'to'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeEmployeeSessionPagination
     */
    'per_page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeEmployeeSessionPagination
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PpeEmployeeSessionSource = {
    Web: 'WEB',
    App: 'APP'
} as const;

export type PpeEmployeeSessionSource = typeof PpeEmployeeSessionSource[keyof typeof PpeEmployeeSessionSource];


/**
 * 
 * @export
 * @interface PpeEmployeeStartOrderRequest
 */
export interface PpeEmployeeStartOrderRequest {
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeStartOrderRequest
     */
    'vending_machine_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeStartOrderRequest
     */
    'job_code'?: string;
}
/**
 * 
 * @export
 * @interface PpeEmployeeStartOrderResponse
 */
export interface PpeEmployeeStartOrderResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeStartOrderResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeStartOrderResponse
     */
    'error_message'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeStartOrderResponse
     */
    'order_uuid'?: string;
    /**
     * 
     * @type {number}
     * @memberof PpeEmployeeStartOrderResponse
     */
    'timeout'?: number;
}
/**
 * 
 * @export
 * @interface PpeEmployeeTrackableItem
 */
export interface PpeEmployeeTrackableItem {
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeTrackableItem
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeTrackableItem
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeTrackableItem
     */
    'product_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeTrackableItem
     */
    'product_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeTrackableItem
     */
    'photo_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeTrackableItem
     */
    'asset_tag'?: string;
    /**
     * 
     * @type {PpeEmployeeTrackableItemState}
     * @memberof PpeEmployeeTrackableItem
     */
    'state'?: PpeEmployeeTrackableItemState;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeTrackableItem
     */
    'note'?: string;
    /**
     * 
     * @type {PpeEmployeeItemLocation}
     * @memberof PpeEmployeeTrackableItem
     */
    'location'?: PpeEmployeeItemLocation;
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeTrackableItem
     */
    'require_attention'?: boolean;
    /**
     * 
     * @type {PpeEmployeeTrackableItemInUseSession}
     * @memberof PpeEmployeeTrackableItem
     */
    'current_in_use'?: PpeEmployeeTrackableItemInUseSession;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeTrackableItem
     */
    'organization_name'?: string;
}
/**
 * 
 * @export
 * @interface PpeEmployeeTrackableItemInUseSession
 */
export interface PpeEmployeeTrackableItemInUseSession {
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeTrackableItemInUseSession
     */
    'in_use'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeTrackableItemInUseSession
     */
    'employee_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeTrackableItemInUseSession
     */
    'employee_uuid'?: string;
    /**
     * 
     * @type {number}
     * @memberof PpeEmployeeTrackableItemInUseSession
     */
    'start'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeEmployeeTrackableItemInUseSession
     */
    'finish'?: number;
    /**
     * 
     * @type {PpeEmployeeReturnInformation}
     * @memberof PpeEmployeeTrackableItemInUseSession
     */
    'return_information'?: PpeEmployeeReturnInformation;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PpeEmployeeTrackableItemState = {
    Draft: 'DRAFT',
    Inventory: 'INVENTORY',
    Ready: 'READY',
    InUse: 'IN_USE',
    Returned: 'RETURNED',
    PendingInspection: 'PENDING_INSPECTION',
    Lost: 'LOST',
    Retired: 'RETIRED',
    Deleted: 'DELETED'
} as const;

export type PpeEmployeeTrackableItemState = typeof PpeEmployeeTrackableItemState[keyof typeof PpeEmployeeTrackableItemState];


/**
 * 
 * @export
 * @interface PpeEmployeeTransaction
 */
export interface PpeEmployeeTransaction {
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeTransaction
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeTransaction
     */
    'vending_machine_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeTransaction
     */
    'vending_machine_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeTransaction
     */
    'vending_machine_site'?: string;
    /**
     * 
     * @type {number}
     * @memberof PpeEmployeeTransaction
     */
    'mdb_item_number'?: number;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeTransaction
     */
    'selection_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeTransaction
     */
    'key_card_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeTransaction
     */
    'product_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeTransaction
     */
    'product_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeTransaction
     */
    'product_category'?: string;
    /**
     * 
     * @type {number}
     * @memberof PpeEmployeeTransaction
     */
    'timestamp'?: number;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeTransaction
     */
    'organization_name'?: string;
}
/**
 * 
 * @export
 * @interface PpeEmployeeTransactionCsv
 */
export interface PpeEmployeeTransactionCsv {
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeTransactionCsv
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeTransactionCsv
     */
    'employee_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeTransactionCsv
     */
    'key_card_number'?: string;
    /**
     * 
     * @type {number}
     * @memberof PpeEmployeeTransactionCsv
     */
    'timestamp'?: number;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeTransactionCsv
     */
    'product_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof PpeEmployeeTransactionCsv
     */
    'mdb_item_number'?: number;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeTransactionCsv
     */
    'vending_machine_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeTransactionCsv
     */
    'vending_machine_site'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PpeEmployeeTransactionItemType = {
    Normal: 'NORMAL',
    Trackable: 'TRACKABLE'
} as const;

export type PpeEmployeeTransactionItemType = typeof PpeEmployeeTransactionItemType[keyof typeof PpeEmployeeTransactionItemType];


/**
 * 
 * @export
 * @enum {string}
 */

export const PpeEmployeeTransactionMachineType = {
    DispensingMachine: 'DISPENSING_MACHINE',
    LockerMachine: 'LOCKER_MACHINE',
    Neither: 'NEITHER'
} as const;

export type PpeEmployeeTransactionMachineType = typeof PpeEmployeeTransactionMachineType[keyof typeof PpeEmployeeTransactionMachineType];


/**
 * 
 * @export
 * @interface PpeEmployeeTransactionPagination
 */
export interface PpeEmployeeTransactionPagination {
    /**
     * 
     * @type {number}
     * @memberof PpeEmployeeTransactionPagination
     */
    'current_page'?: number;
    /**
     * 
     * @type {Array<PpeEmployeeTransaction>}
     * @memberof PpeEmployeeTransactionPagination
     */
    'transactions'?: Array<PpeEmployeeTransaction>;
    /**
     * 
     * @type {number}
     * @memberof PpeEmployeeTransactionPagination
     */
    'from'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeEmployeeTransactionPagination
     */
    'to'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeEmployeeTransactionPagination
     */
    'per_page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeEmployeeTransactionPagination
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface PpeEmployeeTransactionPaginationV2
 */
export interface PpeEmployeeTransactionPaginationV2 {
    /**
     * 
     * @type {number}
     * @memberof PpeEmployeeTransactionPaginationV2
     */
    'current_page'?: number;
    /**
     * 
     * @type {Array<PpeEmployeeTransactionV2>}
     * @memberof PpeEmployeeTransactionPaginationV2
     */
    'transactions'?: Array<PpeEmployeeTransactionV2>;
    /**
     * 
     * @type {number}
     * @memberof PpeEmployeeTransactionPaginationV2
     */
    'from'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeEmployeeTransactionPaginationV2
     */
    'to'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeEmployeeTransactionPaginationV2
     */
    'per_page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PpeEmployeeTransactionPaginationV2
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface PpeEmployeeTransactionV2
 */
export interface PpeEmployeeTransactionV2 {
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeTransactionV2
     */
    'uuid'?: string;
    /**
     * 
     * @type {PpeEmployeeTransactionMachineType}
     * @memberof PpeEmployeeTransactionV2
     */
    'machine_type'?: PpeEmployeeTransactionMachineType;
    /**
     * 
     * @type {PpeEmployeeTransactionItemType}
     * @memberof PpeEmployeeTransactionV2
     */
    'item_type'?: PpeEmployeeTransactionItemType;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeTransactionV2
     */
    'machine_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeTransactionV2
     */
    'machine_site'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeTransactionV2
     */
    'selection_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeTransactionV2
     */
    'product_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof PpeEmployeeTransactionV2
     */
    'start_epoch'?: number;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeTransactionV2
     */
    'organization_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof PpeEmployeeTransactionV2
     */
    'end_epoch'?: number;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeTransactionV2
     */
    'job_id'?: string;
}
/**
 * 
 * @export
 * @interface PpeEmployeeUnlockOrderResponse
 */
export interface PpeEmployeeUnlockOrderResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeUnlockOrderResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeUnlockOrderResponse
     */
    'error_message'?: string;
}
/**
 * 
 * @export
 * @interface PpeEmployeeUserDetail
 */
export interface PpeEmployeeUserDetail {
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeUserDetail
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeUserDetail
     */
    'company_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeUserDetail
     */
    'primary_email'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PpeEmployeeUserDetail
     */
    'emails'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeUserDetail
     */
    'employee_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeUserDetail
     */
    'timezone'?: string;
}
/**
 * 
 * @export
 * @interface PpeEmployeeVendingMachine
 */
export interface PpeEmployeeVendingMachine {
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeVendingMachine
     */
    'uuid'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeVendingMachine
     */
    'online'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PpeEmployeeVendingMachine
     */
    'busy'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeVendingMachine
     */
    'photo_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeVendingMachine
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PpeEmployeeVendingMachine
     */
    'site'?: string;
}
/**
 * 
 * @export
 * @interface ProtobufAny
 */
export interface ProtobufAny {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ProtobufAny
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface RpcStatus
 */
export interface RpcStatus {
    /**
     * 
     * @type {number}
     * @memberof RpcStatus
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof RpcStatus
     */
    'message'?: string;
    /**
     * 
     * @type {Array<ProtobufAny>}
     * @memberof RpcStatus
     */
    'details'?: Array<ProtobufAny>;
}

/**
 * CodeServiceApi - axios parameter creator
 * @export
 */
export const CodeServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Search vending machine, locker, trackable item by code
         * @param {PpeEmployeeSearchByScannedCodeRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        codeServiceSearchByScannedCode: async (body: PpeEmployeeSearchByScannedCodeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('codeServiceSearchByScannedCode', 'body', body)
            const localVarPath = `/api-e/v1/code/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CodeServiceApi - functional programming interface
 * @export
 */
export const CodeServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CodeServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Search vending machine, locker, trackable item by code
         * @param {PpeEmployeeSearchByScannedCodeRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async codeServiceSearchByScannedCode(body: PpeEmployeeSearchByScannedCodeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeEmployeeSearchByScannedCodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.codeServiceSearchByScannedCode(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CodeServiceApi - factory interface
 * @export
 */
export const CodeServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CodeServiceApiFp(configuration)
    return {
        /**
         * 
         * @summary Search vending machine, locker, trackable item by code
         * @param {PpeEmployeeSearchByScannedCodeRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        codeServiceSearchByScannedCode(body: PpeEmployeeSearchByScannedCodeRequest, options?: any): AxiosPromise<PpeEmployeeSearchByScannedCodeResponse> {
            return localVarFp.codeServiceSearchByScannedCode(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CodeServiceApi - object-oriented interface
 * @export
 * @class CodeServiceApi
 * @extends {BaseAPI}
 */
export class CodeServiceApi extends BaseAPI {
    /**
     * 
     * @summary Search vending machine, locker, trackable item by code
     * @param {PpeEmployeeSearchByScannedCodeRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeServiceApi
     */
    public codeServiceSearchByScannedCode(body: PpeEmployeeSearchByScannedCodeRequest, options?: AxiosRequestConfig) {
        return CodeServiceApiFp(this.configuration).codeServiceSearchByScannedCode(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * JobServiceApi - axios parameter creator
 * @export
 */
export const JobServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List all enabled jobs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobServiceListJobs: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api-e/v1/jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JobServiceApi - functional programming interface
 * @export
 */
export const JobServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JobServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List all enabled jobs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jobServiceListJobs(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeEmployeeListJobsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jobServiceListJobs(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * JobServiceApi - factory interface
 * @export
 */
export const JobServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JobServiceApiFp(configuration)
    return {
        /**
         * 
         * @summary List all enabled jobs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobServiceListJobs(options?: any): AxiosPromise<PpeEmployeeListJobsResponse> {
            return localVarFp.jobServiceListJobs(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * JobServiceApi - object-oriented interface
 * @export
 * @class JobServiceApi
 * @extends {BaseAPI}
 */
export class JobServiceApi extends BaseAPI {
    /**
     * 
     * @summary List all enabled jobs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobServiceApi
     */
    public jobServiceListJobs(options?: AxiosRequestConfig) {
        return JobServiceApiFp(this.configuration).jobServiceListJobs(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LockerServiceApi - axios parameter creator
 * @export
 */
export const LockerServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary confirm a trackable item has returned to the locker
         * @param {string} lockerUuid 
         * @param {string} cellUuid 
         * @param {InlineObject1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockerServiceConfirmReturnItemToLocker: async (lockerUuid: string, cellUuid: string, body: InlineObject1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lockerUuid' is not null or undefined
            assertParamExists('lockerServiceConfirmReturnItemToLocker', 'lockerUuid', lockerUuid)
            // verify required parameter 'cellUuid' is not null or undefined
            assertParamExists('lockerServiceConfirmReturnItemToLocker', 'cellUuid', cellUuid)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('lockerServiceConfirmReturnItemToLocker', 'body', body)
            const localVarPath = `/api-e/v1/lockers/{locker_uuid}/cells/{cell_uuid}/return-confirmation`
                .replace(`{${"locker_uuid"}}`, encodeURIComponent(String(lockerUuid)))
                .replace(`{${"cell_uuid"}}`, encodeURIComponent(String(cellUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a single locker cell information
         * @param {string} lockerUuid 
         * @param {string} cellUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockerServiceGetLockerCell: async (lockerUuid: string, cellUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lockerUuid' is not null or undefined
            assertParamExists('lockerServiceGetLockerCell', 'lockerUuid', lockerUuid)
            // verify required parameter 'cellUuid' is not null or undefined
            assertParamExists('lockerServiceGetLockerCell', 'cellUuid', cellUuid)
            const localVarPath = `/api-e/v1/lockers/{locker_uuid}/cells/{cell_uuid}`
                .replace(`{${"locker_uuid"}}`, encodeURIComponent(String(lockerUuid)))
                .replace(`{${"cell_uuid"}}`, encodeURIComponent(String(cellUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the cell information of a specific locker
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockerServiceGetLockerInformation: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('lockerServiceGetLockerInformation', 'uuid', uuid)
            const localVarPath = `/api-e/v1/lockers/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve all lockers that the user have access
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockerServiceListLockers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api-e/v1/lockers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary request to open cell door to return trackable item
         * @param {string} lockerUuid 
         * @param {string} cellUuid 
         * @param {InlineObject2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockerServiceOpenCellDoorToReturn: async (lockerUuid: string, cellUuid: string, body: InlineObject2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lockerUuid' is not null or undefined
            assertParamExists('lockerServiceOpenCellDoorToReturn', 'lockerUuid', lockerUuid)
            // verify required parameter 'cellUuid' is not null or undefined
            assertParamExists('lockerServiceOpenCellDoorToReturn', 'cellUuid', cellUuid)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('lockerServiceOpenCellDoorToReturn', 'body', body)
            const localVarPath = `/api-e/v1/lockers/{locker_uuid}/cells/{cell_uuid}/return-open`
                .replace(`{${"locker_uuid"}}`, encodeURIComponent(String(lockerUuid)))
                .replace(`{${"cell_uuid"}}`, encodeURIComponent(String(cellUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary pickup item from locker
         * @param {string} lockerUuid 
         * @param {string} cellUuid 
         * @param {InlineObject} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockerServicePickupItemFromLocker: async (lockerUuid: string, cellUuid: string, body: InlineObject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lockerUuid' is not null or undefined
            assertParamExists('lockerServicePickupItemFromLocker', 'lockerUuid', lockerUuid)
            // verify required parameter 'cellUuid' is not null or undefined
            assertParamExists('lockerServicePickupItemFromLocker', 'cellUuid', cellUuid)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('lockerServicePickupItemFromLocker', 'body', body)
            const localVarPath = `/api-e/v1/lockers/{locker_uuid}/cells/{cell_uuid}/pickups`
                .replace(`{${"locker_uuid"}}`, encodeURIComponent(String(lockerUuid)))
                .replace(`{${"cell_uuid"}}`, encodeURIComponent(String(cellUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LockerServiceApi - functional programming interface
 * @export
 */
export const LockerServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LockerServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary confirm a trackable item has returned to the locker
         * @param {string} lockerUuid 
         * @param {string} cellUuid 
         * @param {InlineObject1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lockerServiceConfirmReturnItemToLocker(lockerUuid: string, cellUuid: string, body: InlineObject1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeEmployeeConfirmReturnItemToLockerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lockerServiceConfirmReturnItemToLocker(lockerUuid, cellUuid, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get a single locker cell information
         * @param {string} lockerUuid 
         * @param {string} cellUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lockerServiceGetLockerCell(lockerUuid: string, cellUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeEmployeeGetLockerCellResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lockerServiceGetLockerCell(lockerUuid, cellUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the cell information of a specific locker
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lockerServiceGetLockerInformation(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeEmployeeGetLockerInformationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lockerServiceGetLockerInformation(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve all lockers that the user have access
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lockerServiceListLockers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeEmployeeListLockersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lockerServiceListLockers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary request to open cell door to return trackable item
         * @param {string} lockerUuid 
         * @param {string} cellUuid 
         * @param {InlineObject2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lockerServiceOpenCellDoorToReturn(lockerUuid: string, cellUuid: string, body: InlineObject2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeEmployeeOpenCellDoorToReturnResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lockerServiceOpenCellDoorToReturn(lockerUuid, cellUuid, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary pickup item from locker
         * @param {string} lockerUuid 
         * @param {string} cellUuid 
         * @param {InlineObject} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lockerServicePickupItemFromLocker(lockerUuid: string, cellUuid: string, body: InlineObject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeEmployeePickupItemFromLockerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lockerServicePickupItemFromLocker(lockerUuid, cellUuid, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LockerServiceApi - factory interface
 * @export
 */
export const LockerServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LockerServiceApiFp(configuration)
    return {
        /**
         * 
         * @summary confirm a trackable item has returned to the locker
         * @param {string} lockerUuid 
         * @param {string} cellUuid 
         * @param {InlineObject1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockerServiceConfirmReturnItemToLocker(lockerUuid: string, cellUuid: string, body: InlineObject1, options?: any): AxiosPromise<PpeEmployeeConfirmReturnItemToLockerResponse> {
            return localVarFp.lockerServiceConfirmReturnItemToLocker(lockerUuid, cellUuid, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a single locker cell information
         * @param {string} lockerUuid 
         * @param {string} cellUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockerServiceGetLockerCell(lockerUuid: string, cellUuid: string, options?: any): AxiosPromise<PpeEmployeeGetLockerCellResponse> {
            return localVarFp.lockerServiceGetLockerCell(lockerUuid, cellUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the cell information of a specific locker
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockerServiceGetLockerInformation(uuid: string, options?: any): AxiosPromise<PpeEmployeeGetLockerInformationResponse> {
            return localVarFp.lockerServiceGetLockerInformation(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve all lockers that the user have access
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockerServiceListLockers(options?: any): AxiosPromise<PpeEmployeeListLockersResponse> {
            return localVarFp.lockerServiceListLockers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary request to open cell door to return trackable item
         * @param {string} lockerUuid 
         * @param {string} cellUuid 
         * @param {InlineObject2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockerServiceOpenCellDoorToReturn(lockerUuid: string, cellUuid: string, body: InlineObject2, options?: any): AxiosPromise<PpeEmployeeOpenCellDoorToReturnResponse> {
            return localVarFp.lockerServiceOpenCellDoorToReturn(lockerUuid, cellUuid, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary pickup item from locker
         * @param {string} lockerUuid 
         * @param {string} cellUuid 
         * @param {InlineObject} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockerServicePickupItemFromLocker(lockerUuid: string, cellUuid: string, body: InlineObject, options?: any): AxiosPromise<PpeEmployeePickupItemFromLockerResponse> {
            return localVarFp.lockerServicePickupItemFromLocker(lockerUuid, cellUuid, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LockerServiceApi - object-oriented interface
 * @export
 * @class LockerServiceApi
 * @extends {BaseAPI}
 */
export class LockerServiceApi extends BaseAPI {
    /**
     * 
     * @summary confirm a trackable item has returned to the locker
     * @param {string} lockerUuid 
     * @param {string} cellUuid 
     * @param {InlineObject1} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LockerServiceApi
     */
    public lockerServiceConfirmReturnItemToLocker(lockerUuid: string, cellUuid: string, body: InlineObject1, options?: AxiosRequestConfig) {
        return LockerServiceApiFp(this.configuration).lockerServiceConfirmReturnItemToLocker(lockerUuid, cellUuid, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a single locker cell information
     * @param {string} lockerUuid 
     * @param {string} cellUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LockerServiceApi
     */
    public lockerServiceGetLockerCell(lockerUuid: string, cellUuid: string, options?: AxiosRequestConfig) {
        return LockerServiceApiFp(this.configuration).lockerServiceGetLockerCell(lockerUuid, cellUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the cell information of a specific locker
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LockerServiceApi
     */
    public lockerServiceGetLockerInformation(uuid: string, options?: AxiosRequestConfig) {
        return LockerServiceApiFp(this.configuration).lockerServiceGetLockerInformation(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve all lockers that the user have access
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LockerServiceApi
     */
    public lockerServiceListLockers(options?: AxiosRequestConfig) {
        return LockerServiceApiFp(this.configuration).lockerServiceListLockers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary request to open cell door to return trackable item
     * @param {string} lockerUuid 
     * @param {string} cellUuid 
     * @param {InlineObject2} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LockerServiceApi
     */
    public lockerServiceOpenCellDoorToReturn(lockerUuid: string, cellUuid: string, body: InlineObject2, options?: AxiosRequestConfig) {
        return LockerServiceApiFp(this.configuration).lockerServiceOpenCellDoorToReturn(lockerUuid, cellUuid, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary pickup item from locker
     * @param {string} lockerUuid 
     * @param {string} cellUuid 
     * @param {InlineObject} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LockerServiceApi
     */
    public lockerServicePickupItemFromLocker(lockerUuid: string, cellUuid: string, body: InlineObject, options?: AxiosRequestConfig) {
        return LockerServiceApiFp(this.configuration).lockerServicePickupItemFromLocker(lockerUuid, cellUuid, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderServiceApi - axios parameter creator
 * @export
 */
export const OrderServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Confirm an order
         * @param {string} uuid 
         * @param {InlineObject3} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderServiceConfirmOrder: async (uuid: string, body: InlineObject3, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('orderServiceConfirmOrder', 'uuid', uuid)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('orderServiceConfirmOrder', 'body', body)
            const localVarPath = `/api-e/v1/orders/{uuid}/confirm`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get order status
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderServiceGetOrderStatus: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('orderServiceGetOrderStatus', 'uuid', uuid)
            const localVarPath = `/api-e/v1/orders/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User initialise an order from the client side
         * @param {PpeEmployeeStartOrderRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderServiceStartOrder: async (body: PpeEmployeeStartOrderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('orderServiceStartOrder', 'body', body)
            const localVarPath = `/api-e/v1/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User unlock an order from the client side
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderServiceUnlockOrder: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('orderServiceUnlockOrder', 'uuid', uuid)
            const localVarPath = `/api-e/v1/orders/{uuid}/unlock`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderServiceApi - functional programming interface
 * @export
 */
export const OrderServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Confirm an order
         * @param {string} uuid 
         * @param {InlineObject3} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderServiceConfirmOrder(uuid: string, body: InlineObject3, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeEmployeeConfirmOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderServiceConfirmOrder(uuid, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get order status
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderServiceGetOrderStatus(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeEmployeeGetOrderStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderServiceGetOrderStatus(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary User initialise an order from the client side
         * @param {PpeEmployeeStartOrderRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderServiceStartOrder(body: PpeEmployeeStartOrderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeEmployeeStartOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderServiceStartOrder(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary User unlock an order from the client side
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderServiceUnlockOrder(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeEmployeeUnlockOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderServiceUnlockOrder(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderServiceApi - factory interface
 * @export
 */
export const OrderServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderServiceApiFp(configuration)
    return {
        /**
         * 
         * @summary Confirm an order
         * @param {string} uuid 
         * @param {InlineObject3} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderServiceConfirmOrder(uuid: string, body: InlineObject3, options?: any): AxiosPromise<PpeEmployeeConfirmOrderResponse> {
            return localVarFp.orderServiceConfirmOrder(uuid, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get order status
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderServiceGetOrderStatus(uuid: string, options?: any): AxiosPromise<PpeEmployeeGetOrderStatusResponse> {
            return localVarFp.orderServiceGetOrderStatus(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User initialise an order from the client side
         * @param {PpeEmployeeStartOrderRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderServiceStartOrder(body: PpeEmployeeStartOrderRequest, options?: any): AxiosPromise<PpeEmployeeStartOrderResponse> {
            return localVarFp.orderServiceStartOrder(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User unlock an order from the client side
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderServiceUnlockOrder(uuid: string, options?: any): AxiosPromise<PpeEmployeeUnlockOrderResponse> {
            return localVarFp.orderServiceUnlockOrder(uuid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderServiceApi - object-oriented interface
 * @export
 * @class OrderServiceApi
 * @extends {BaseAPI}
 */
export class OrderServiceApi extends BaseAPI {
    /**
     * 
     * @summary Confirm an order
     * @param {string} uuid 
     * @param {InlineObject3} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderServiceApi
     */
    public orderServiceConfirmOrder(uuid: string, body: InlineObject3, options?: AxiosRequestConfig) {
        return OrderServiceApiFp(this.configuration).orderServiceConfirmOrder(uuid, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get order status
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderServiceApi
     */
    public orderServiceGetOrderStatus(uuid: string, options?: AxiosRequestConfig) {
        return OrderServiceApiFp(this.configuration).orderServiceGetOrderStatus(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User initialise an order from the client side
     * @param {PpeEmployeeStartOrderRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderServiceApi
     */
    public orderServiceStartOrder(body: PpeEmployeeStartOrderRequest, options?: AxiosRequestConfig) {
        return OrderServiceApiFp(this.configuration).orderServiceStartOrder(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User unlock an order from the client side
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderServiceApi
     */
    public orderServiceUnlockOrder(uuid: string, options?: AxiosRequestConfig) {
        return OrderServiceApiFp(this.configuration).orderServiceUnlockOrder(uuid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationServiceApi - axios parameter creator
 * @export
 */
export const OrganizationServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get logo for the current organization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationServiceGetLogo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api-e/v1/organization/get-logo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get organization detail for current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationServiceGetOrganizationDetail: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api-e/v1/organization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationServiceApi - functional programming interface
 * @export
 */
export const OrganizationServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get logo for the current organization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationServiceGetLogo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeEmployeeGetLogoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationServiceGetLogo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get organization detail for current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationServiceGetOrganizationDetail(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeEmployeeGetOrganizationDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationServiceGetOrganizationDetail(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationServiceApi - factory interface
 * @export
 */
export const OrganizationServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationServiceApiFp(configuration)
    return {
        /**
         * 
         * @summary Get logo for the current organization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationServiceGetLogo(options?: any): AxiosPromise<PpeEmployeeGetLogoResponse> {
            return localVarFp.organizationServiceGetLogo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get organization detail for current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationServiceGetOrganizationDetail(options?: any): AxiosPromise<PpeEmployeeGetOrganizationDetailResponse> {
            return localVarFp.organizationServiceGetOrganizationDetail(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationServiceApi - object-oriented interface
 * @export
 * @class OrganizationServiceApi
 * @extends {BaseAPI}
 */
export class OrganizationServiceApi extends BaseAPI {
    /**
     * 
     * @summary Get logo for the current organization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationServiceApi
     */
    public organizationServiceGetLogo(options?: AxiosRequestConfig) {
        return OrganizationServiceApiFp(this.configuration).organizationServiceGetLogo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get organization detail for current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationServiceApi
     */
    public organizationServiceGetOrganizationDetail(options?: AxiosRequestConfig) {
        return OrganizationServiceApiFp(this.configuration).organizationServiceGetOrganizationDetail(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SsoServiceApi - axios parameter creator
 * @export
 */
export const SsoServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Exchange session token with a Google access token
         * @param {PpeEmployeeExchangeSessionTokenWithGoogleAccessTokenRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoServiceExchangeSessionTokenWithGoogleAccessToken: async (body: PpeEmployeeExchangeSessionTokenWithGoogleAccessTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('ssoServiceExchangeSessionTokenWithGoogleAccessToken', 'body', body)
            const localVarPath = `/api-e/v1/sso/google/exchange-session-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Exchange session token with a Microsoft access token
         * @param {PpeEmployeeExchangeSessionTokenWithMicrosoftAccessTokenRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoServiceExchangeSessionTokenWithMicrosoftAccessToken: async (body: PpeEmployeeExchangeSessionTokenWithMicrosoftAccessTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('ssoServiceExchangeSessionTokenWithMicrosoftAccessToken', 'body', body)
            const localVarPath = `/api-e/v1/sso/microsoft/exchange-session-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Google SSO config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoServiceGetGoogleSsoConfig: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api-e/v1/sso/google/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Microsoft SSO config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoServiceGetMicrosoftSsoConfig: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api-e/v1/sso/microsoft/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SsoServiceApi - functional programming interface
 * @export
 */
export const SsoServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SsoServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Exchange session token with a Google access token
         * @param {PpeEmployeeExchangeSessionTokenWithGoogleAccessTokenRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ssoServiceExchangeSessionTokenWithGoogleAccessToken(body: PpeEmployeeExchangeSessionTokenWithGoogleAccessTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeEmployeeExchangeSessionTokenWithGoogleAccessTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ssoServiceExchangeSessionTokenWithGoogleAccessToken(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Exchange session token with a Microsoft access token
         * @param {PpeEmployeeExchangeSessionTokenWithMicrosoftAccessTokenRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ssoServiceExchangeSessionTokenWithMicrosoftAccessToken(body: PpeEmployeeExchangeSessionTokenWithMicrosoftAccessTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeEmployeeExchangeSessionTokenWithMicrosoftAccessTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ssoServiceExchangeSessionTokenWithMicrosoftAccessToken(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Google SSO config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ssoServiceGetGoogleSsoConfig(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeEmployeeGetGoogleSsoConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ssoServiceGetGoogleSsoConfig(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Microsoft SSO config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ssoServiceGetMicrosoftSsoConfig(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeEmployeeGetMicrosoftSsoConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ssoServiceGetMicrosoftSsoConfig(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SsoServiceApi - factory interface
 * @export
 */
export const SsoServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SsoServiceApiFp(configuration)
    return {
        /**
         * 
         * @summary Exchange session token with a Google access token
         * @param {PpeEmployeeExchangeSessionTokenWithGoogleAccessTokenRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoServiceExchangeSessionTokenWithGoogleAccessToken(body: PpeEmployeeExchangeSessionTokenWithGoogleAccessTokenRequest, options?: any): AxiosPromise<PpeEmployeeExchangeSessionTokenWithGoogleAccessTokenResponse> {
            return localVarFp.ssoServiceExchangeSessionTokenWithGoogleAccessToken(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Exchange session token with a Microsoft access token
         * @param {PpeEmployeeExchangeSessionTokenWithMicrosoftAccessTokenRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoServiceExchangeSessionTokenWithMicrosoftAccessToken(body: PpeEmployeeExchangeSessionTokenWithMicrosoftAccessTokenRequest, options?: any): AxiosPromise<PpeEmployeeExchangeSessionTokenWithMicrosoftAccessTokenResponse> {
            return localVarFp.ssoServiceExchangeSessionTokenWithMicrosoftAccessToken(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Google SSO config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoServiceGetGoogleSsoConfig(options?: any): AxiosPromise<PpeEmployeeGetGoogleSsoConfigResponse> {
            return localVarFp.ssoServiceGetGoogleSsoConfig(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Microsoft SSO config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoServiceGetMicrosoftSsoConfig(options?: any): AxiosPromise<PpeEmployeeGetMicrosoftSsoConfigResponse> {
            return localVarFp.ssoServiceGetMicrosoftSsoConfig(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SsoServiceApi - object-oriented interface
 * @export
 * @class SsoServiceApi
 * @extends {BaseAPI}
 */
export class SsoServiceApi extends BaseAPI {
    /**
     * 
     * @summary Exchange session token with a Google access token
     * @param {PpeEmployeeExchangeSessionTokenWithGoogleAccessTokenRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SsoServiceApi
     */
    public ssoServiceExchangeSessionTokenWithGoogleAccessToken(body: PpeEmployeeExchangeSessionTokenWithGoogleAccessTokenRequest, options?: AxiosRequestConfig) {
        return SsoServiceApiFp(this.configuration).ssoServiceExchangeSessionTokenWithGoogleAccessToken(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Exchange session token with a Microsoft access token
     * @param {PpeEmployeeExchangeSessionTokenWithMicrosoftAccessTokenRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SsoServiceApi
     */
    public ssoServiceExchangeSessionTokenWithMicrosoftAccessToken(body: PpeEmployeeExchangeSessionTokenWithMicrosoftAccessTokenRequest, options?: AxiosRequestConfig) {
        return SsoServiceApiFp(this.configuration).ssoServiceExchangeSessionTokenWithMicrosoftAccessToken(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Google SSO config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SsoServiceApi
     */
    public ssoServiceGetGoogleSsoConfig(options?: AxiosRequestConfig) {
        return SsoServiceApiFp(this.configuration).ssoServiceGetGoogleSsoConfig(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Microsoft SSO config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SsoServiceApi
     */
    public ssoServiceGetMicrosoftSsoConfig(options?: AxiosRequestConfig) {
        return SsoServiceApiFp(this.configuration).ssoServiceGetMicrosoftSsoConfig(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TrackableItemServiceApi - axios parameter creator
 * @export
 */
export const TrackableItemServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Claim a trackable item
         * @param {string} uuid 
         * @param {InlineObject6} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackableItemServiceClaimTrackableItem: async (uuid: string, body: InlineObject6, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('trackableItemServiceClaimTrackableItem', 'uuid', uuid)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('trackableItemServiceClaimTrackableItem', 'body', body)
            const localVarPath = `/api-e/v1/trackable-items/{uuid}/sessions`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve all trackable items that currently in use by me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackableItemServiceGetTrackableItemsCurrentlyInUseByMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api-e/v1/trackable-items/in-use-by-me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve all trackable items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackableItemServiceListTrackableItems: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api-e/v1/trackable-items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Report a trackable item requires inspection
         * @param {string} uuid 
         * @param {InlineObject4} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackableItemServiceReportTrackableItemRequiresInspection: async (uuid: string, body: InlineObject4, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('trackableItemServiceReportTrackableItemRequiresInspection', 'uuid', uuid)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('trackableItemServiceReportTrackableItemRequiresInspection', 'body', body)
            const localVarPath = `/api-e/v1/trackable-items/{uuid}/require-inspection`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return a trackable item
         * @param {string} uuid 
         * @param {InlineObject5} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackableItemServiceReturnTrackableItems: async (uuid: string, body: InlineObject5, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('trackableItemServiceReturnTrackableItems', 'uuid', uuid)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('trackableItemServiceReturnTrackableItems', 'body', body)
            const localVarPath = `/api-e/v1/trackable-items/{uuid}/return`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search a trackable item by either tag or uuid
         * @param {PpeEmployeeSearchTrackableItemRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackableItemServiceSearchTrackableItem: async (body: PpeEmployeeSearchTrackableItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('trackableItemServiceSearchTrackableItem', 'body', body)
            const localVarPath = `/api-e/v1/trackable-items/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrackableItemServiceApi - functional programming interface
 * @export
 */
export const TrackableItemServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TrackableItemServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Claim a trackable item
         * @param {string} uuid 
         * @param {InlineObject6} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trackableItemServiceClaimTrackableItem(uuid: string, body: InlineObject6, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeEmployeeClaimTrackableItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trackableItemServiceClaimTrackableItem(uuid, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve all trackable items that currently in use by me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trackableItemServiceGetTrackableItemsCurrentlyInUseByMe(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeEmployeeGetListOfTrackableItemCurrentInUseByMeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trackableItemServiceGetTrackableItemsCurrentlyInUseByMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve all trackable items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trackableItemServiceListTrackableItems(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeEmployeeListTrackableItemsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trackableItemServiceListTrackableItems(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Report a trackable item requires inspection
         * @param {string} uuid 
         * @param {InlineObject4} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trackableItemServiceReportTrackableItemRequiresInspection(uuid: string, body: InlineObject4, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeEmployeeReportTrackableItemRequiresInspectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trackableItemServiceReportTrackableItemRequiresInspection(uuid, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return a trackable item
         * @param {string} uuid 
         * @param {InlineObject5} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trackableItemServiceReturnTrackableItems(uuid: string, body: InlineObject5, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeEmployeeReturnTrackableItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trackableItemServiceReturnTrackableItems(uuid, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search a trackable item by either tag or uuid
         * @param {PpeEmployeeSearchTrackableItemRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trackableItemServiceSearchTrackableItem(body: PpeEmployeeSearchTrackableItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeEmployeeSearchTrackableItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trackableItemServiceSearchTrackableItem(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TrackableItemServiceApi - factory interface
 * @export
 */
export const TrackableItemServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TrackableItemServiceApiFp(configuration)
    return {
        /**
         * 
         * @summary Claim a trackable item
         * @param {string} uuid 
         * @param {InlineObject6} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackableItemServiceClaimTrackableItem(uuid: string, body: InlineObject6, options?: any): AxiosPromise<PpeEmployeeClaimTrackableItemResponse> {
            return localVarFp.trackableItemServiceClaimTrackableItem(uuid, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve all trackable items that currently in use by me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackableItemServiceGetTrackableItemsCurrentlyInUseByMe(options?: any): AxiosPromise<PpeEmployeeGetListOfTrackableItemCurrentInUseByMeResponse> {
            return localVarFp.trackableItemServiceGetTrackableItemsCurrentlyInUseByMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve all trackable items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackableItemServiceListTrackableItems(options?: any): AxiosPromise<PpeEmployeeListTrackableItemsResponse> {
            return localVarFp.trackableItemServiceListTrackableItems(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Report a trackable item requires inspection
         * @param {string} uuid 
         * @param {InlineObject4} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackableItemServiceReportTrackableItemRequiresInspection(uuid: string, body: InlineObject4, options?: any): AxiosPromise<PpeEmployeeReportTrackableItemRequiresInspectionResponse> {
            return localVarFp.trackableItemServiceReportTrackableItemRequiresInspection(uuid, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return a trackable item
         * @param {string} uuid 
         * @param {InlineObject5} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackableItemServiceReturnTrackableItems(uuid: string, body: InlineObject5, options?: any): AxiosPromise<PpeEmployeeReturnTrackableItemResponse> {
            return localVarFp.trackableItemServiceReturnTrackableItems(uuid, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search a trackable item by either tag or uuid
         * @param {PpeEmployeeSearchTrackableItemRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackableItemServiceSearchTrackableItem(body: PpeEmployeeSearchTrackableItemRequest, options?: any): AxiosPromise<PpeEmployeeSearchTrackableItemResponse> {
            return localVarFp.trackableItemServiceSearchTrackableItem(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TrackableItemServiceApi - object-oriented interface
 * @export
 * @class TrackableItemServiceApi
 * @extends {BaseAPI}
 */
export class TrackableItemServiceApi extends BaseAPI {
    /**
     * 
     * @summary Claim a trackable item
     * @param {string} uuid 
     * @param {InlineObject6} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackableItemServiceApi
     */
    public trackableItemServiceClaimTrackableItem(uuid: string, body: InlineObject6, options?: AxiosRequestConfig) {
        return TrackableItemServiceApiFp(this.configuration).trackableItemServiceClaimTrackableItem(uuid, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve all trackable items that currently in use by me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackableItemServiceApi
     */
    public trackableItemServiceGetTrackableItemsCurrentlyInUseByMe(options?: AxiosRequestConfig) {
        return TrackableItemServiceApiFp(this.configuration).trackableItemServiceGetTrackableItemsCurrentlyInUseByMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve all trackable items
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackableItemServiceApi
     */
    public trackableItemServiceListTrackableItems(options?: AxiosRequestConfig) {
        return TrackableItemServiceApiFp(this.configuration).trackableItemServiceListTrackableItems(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Report a trackable item requires inspection
     * @param {string} uuid 
     * @param {InlineObject4} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackableItemServiceApi
     */
    public trackableItemServiceReportTrackableItemRequiresInspection(uuid: string, body: InlineObject4, options?: AxiosRequestConfig) {
        return TrackableItemServiceApiFp(this.configuration).trackableItemServiceReportTrackableItemRequiresInspection(uuid, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return a trackable item
     * @param {string} uuid 
     * @param {InlineObject5} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackableItemServiceApi
     */
    public trackableItemServiceReturnTrackableItems(uuid: string, body: InlineObject5, options?: AxiosRequestConfig) {
        return TrackableItemServiceApiFp(this.configuration).trackableItemServiceReturnTrackableItems(uuid, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search a trackable item by either tag or uuid
     * @param {PpeEmployeeSearchTrackableItemRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackableItemServiceApi
     */
    public trackableItemServiceSearchTrackableItem(body: PpeEmployeeSearchTrackableItemRequest, options?: AxiosRequestConfig) {
        return TrackableItemServiceApiFp(this.configuration).trackableItemServiceSearchTrackableItem(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TransactionServiceApi - axios parameter creator
 * @export
 */
export const TransactionServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all transactions of current user
         * @param {number} [perPage] per_page specifies how many entries to return. If per_page is 0, it means all items.
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionServiceGetTransactions: async (perPage?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api-e/v1/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get transaction\'s csv data for current user
         * @param {PpeEmployeeGetTransactionsCsvRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionServiceGetTransactionsCsv: async (body: PpeEmployeeGetTransactionsCsvRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('transactionServiceGetTransactionsCsv', 'body', body)
            const localVarPath = `/api-e/v1/transactions/csv-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all transactions of current user, include the trackable item transaction
         * @param {number} [perPage] per_page specifies how many entries to return. If per_page is 0, it means all items.
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionServiceGetTransactionsV2: async (perPage?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api-e/v2/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionServiceApi - functional programming interface
 * @export
 */
export const TransactionServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransactionServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all transactions of current user
         * @param {number} [perPage] per_page specifies how many entries to return. If per_page is 0, it means all items.
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionServiceGetTransactions(perPage?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeEmployeeGetTransactionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transactionServiceGetTransactions(perPage, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get transaction\'s csv data for current user
         * @param {PpeEmployeeGetTransactionsCsvRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionServiceGetTransactionsCsv(body: PpeEmployeeGetTransactionsCsvRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeEmployeeGetTransactionsCsvResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transactionServiceGetTransactionsCsv(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all transactions of current user, include the trackable item transaction
         * @param {number} [perPage] per_page specifies how many entries to return. If per_page is 0, it means all items.
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionServiceGetTransactionsV2(perPage?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeEmployeeGetTransactionsResponseV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transactionServiceGetTransactionsV2(perPage, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TransactionServiceApi - factory interface
 * @export
 */
export const TransactionServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransactionServiceApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all transactions of current user
         * @param {number} [perPage] per_page specifies how many entries to return. If per_page is 0, it means all items.
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionServiceGetTransactions(perPage?: number, page?: number, options?: any): AxiosPromise<PpeEmployeeGetTransactionsResponse> {
            return localVarFp.transactionServiceGetTransactions(perPage, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get transaction\'s csv data for current user
         * @param {PpeEmployeeGetTransactionsCsvRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionServiceGetTransactionsCsv(body: PpeEmployeeGetTransactionsCsvRequest, options?: any): AxiosPromise<PpeEmployeeGetTransactionsCsvResponse> {
            return localVarFp.transactionServiceGetTransactionsCsv(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all transactions of current user, include the trackable item transaction
         * @param {number} [perPage] per_page specifies how many entries to return. If per_page is 0, it means all items.
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionServiceGetTransactionsV2(perPage?: number, page?: number, options?: any): AxiosPromise<PpeEmployeeGetTransactionsResponseV2> {
            return localVarFp.transactionServiceGetTransactionsV2(perPage, page, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TransactionServiceApi - object-oriented interface
 * @export
 * @class TransactionServiceApi
 * @extends {BaseAPI}
 */
export class TransactionServiceApi extends BaseAPI {
    /**
     * 
     * @summary Get all transactions of current user
     * @param {number} [perPage] per_page specifies how many entries to return. If per_page is 0, it means all items.
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionServiceApi
     */
    public transactionServiceGetTransactions(perPage?: number, page?: number, options?: AxiosRequestConfig) {
        return TransactionServiceApiFp(this.configuration).transactionServiceGetTransactions(perPage, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get transaction\'s csv data for current user
     * @param {PpeEmployeeGetTransactionsCsvRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionServiceApi
     */
    public transactionServiceGetTransactionsCsv(body: PpeEmployeeGetTransactionsCsvRequest, options?: AxiosRequestConfig) {
        return TransactionServiceApiFp(this.configuration).transactionServiceGetTransactionsCsv(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all transactions of current user, include the trackable item transaction
     * @param {number} [perPage] per_page specifies how many entries to return. If per_page is 0, it means all items.
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionServiceApi
     */
    public transactionServiceGetTransactionsV2(perPage?: number, page?: number, options?: AxiosRequestConfig) {
        return TransactionServiceApiFp(this.configuration).transactionServiceGetTransactionsV2(perPage, page, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserServiceApi - axios parameter creator
 * @export
 */
export const UserServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary exchange access token with a temporary access token
         * @param {PpeEmployeeExchangeSessionTokenWithTemporaryAccessTokenRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userServiceExchangeSessionTokenWithTemporaryAccessToken: async (body: PpeEmployeeExchangeSessionTokenWithTemporaryAccessTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('userServiceExchangeSessionTokenWithTemporaryAccessToken', 'body', body)
            const localVarPath = `/api-e/v1/user/exchange_token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user detail for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userServiceGetDetail: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api-e/v1/user/get-detail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user session for the current user
         * @param {PpeEmployeeGetSessionRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userServiceGetUserSession: async (body: PpeEmployeeGetSessionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('userServiceGetUserSession', 'body', body)
            const localVarPath = `/api-e/v1/user/get-sessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login with a kiosk
         * @param {PpeEmployeeKioskLoginRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userServiceKioskLogin: async (body: PpeEmployeeKioskLoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('userServiceKioskLogin', 'body', body)
            const localVarPath = `/api-e/v1/kiosk/authentications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Authenticate a user by email address
         * @param {PpeEmployeePostAuthenticationRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userServicePostAuthentication: async (body: PpeEmployeePostAuthenticationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('userServicePostAuthentication', 'body', body)
            const localVarPath = `/api-e/v1/user/authentication`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Register fcm token for user
         * @param {PpeEmployeePostFcmTokenRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userServicePostFcm: async (body: PpeEmployeePostFcmTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('userServicePostFcm', 'body', body)
            const localVarPath = `/api-e/v1/user/register-fcm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary issue access token to the user by verify the auth code and verification id
         * @param {PpeEmployeePostVerificationRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userServicePostVerification: async (body: PpeEmployeePostVerificationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('userServicePostVerification', 'body', body)
            const localVarPath = `/api-e/v1/user/verification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserServiceApi - functional programming interface
 * @export
 */
export const UserServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary exchange access token with a temporary access token
         * @param {PpeEmployeeExchangeSessionTokenWithTemporaryAccessTokenRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userServiceExchangeSessionTokenWithTemporaryAccessToken(body: PpeEmployeeExchangeSessionTokenWithTemporaryAccessTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeEmployeeExchangeSessionTokenWithTemporaryAccessTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userServiceExchangeSessionTokenWithTemporaryAccessToken(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user detail for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userServiceGetDetail(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeEmployeeGetDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userServiceGetDetail(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user session for the current user
         * @param {PpeEmployeeGetSessionRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userServiceGetUserSession(body: PpeEmployeeGetSessionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeEmployeeGetSessionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userServiceGetUserSession(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Login with a kiosk
         * @param {PpeEmployeeKioskLoginRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userServiceKioskLogin(body: PpeEmployeeKioskLoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeEmployeeKioskLoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userServiceKioskLogin(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Authenticate a user by email address
         * @param {PpeEmployeePostAuthenticationRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userServicePostAuthentication(body: PpeEmployeePostAuthenticationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeEmployeePostAuthenticationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userServicePostAuthentication(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Register fcm token for user
         * @param {PpeEmployeePostFcmTokenRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userServicePostFcm(body: PpeEmployeePostFcmTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeEmployeePostFcmTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userServicePostFcm(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary issue access token to the user by verify the auth code and verification id
         * @param {PpeEmployeePostVerificationRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userServicePostVerification(body: PpeEmployeePostVerificationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeEmployeePostVerificationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userServicePostVerification(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserServiceApi - factory interface
 * @export
 */
export const UserServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserServiceApiFp(configuration)
    return {
        /**
         * 
         * @summary exchange access token with a temporary access token
         * @param {PpeEmployeeExchangeSessionTokenWithTemporaryAccessTokenRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userServiceExchangeSessionTokenWithTemporaryAccessToken(body: PpeEmployeeExchangeSessionTokenWithTemporaryAccessTokenRequest, options?: any): AxiosPromise<PpeEmployeeExchangeSessionTokenWithTemporaryAccessTokenResponse> {
            return localVarFp.userServiceExchangeSessionTokenWithTemporaryAccessToken(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user detail for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userServiceGetDetail(options?: any): AxiosPromise<PpeEmployeeGetDetailResponse> {
            return localVarFp.userServiceGetDetail(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user session for the current user
         * @param {PpeEmployeeGetSessionRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userServiceGetUserSession(body: PpeEmployeeGetSessionRequest, options?: any): AxiosPromise<PpeEmployeeGetSessionsResponse> {
            return localVarFp.userServiceGetUserSession(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login with a kiosk
         * @param {PpeEmployeeKioskLoginRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userServiceKioskLogin(body: PpeEmployeeKioskLoginRequest, options?: any): AxiosPromise<PpeEmployeeKioskLoginResponse> {
            return localVarFp.userServiceKioskLogin(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Authenticate a user by email address
         * @param {PpeEmployeePostAuthenticationRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userServicePostAuthentication(body: PpeEmployeePostAuthenticationRequest, options?: any): AxiosPromise<PpeEmployeePostAuthenticationResponse> {
            return localVarFp.userServicePostAuthentication(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Register fcm token for user
         * @param {PpeEmployeePostFcmTokenRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userServicePostFcm(body: PpeEmployeePostFcmTokenRequest, options?: any): AxiosPromise<PpeEmployeePostFcmTokenResponse> {
            return localVarFp.userServicePostFcm(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary issue access token to the user by verify the auth code and verification id
         * @param {PpeEmployeePostVerificationRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userServicePostVerification(body: PpeEmployeePostVerificationRequest, options?: any): AxiosPromise<PpeEmployeePostVerificationResponse> {
            return localVarFp.userServicePostVerification(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserServiceApi - object-oriented interface
 * @export
 * @class UserServiceApi
 * @extends {BaseAPI}
 */
export class UserServiceApi extends BaseAPI {
    /**
     * 
     * @summary exchange access token with a temporary access token
     * @param {PpeEmployeeExchangeSessionTokenWithTemporaryAccessTokenRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserServiceApi
     */
    public userServiceExchangeSessionTokenWithTemporaryAccessToken(body: PpeEmployeeExchangeSessionTokenWithTemporaryAccessTokenRequest, options?: AxiosRequestConfig) {
        return UserServiceApiFp(this.configuration).userServiceExchangeSessionTokenWithTemporaryAccessToken(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user detail for the current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserServiceApi
     */
    public userServiceGetDetail(options?: AxiosRequestConfig) {
        return UserServiceApiFp(this.configuration).userServiceGetDetail(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user session for the current user
     * @param {PpeEmployeeGetSessionRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserServiceApi
     */
    public userServiceGetUserSession(body: PpeEmployeeGetSessionRequest, options?: AxiosRequestConfig) {
        return UserServiceApiFp(this.configuration).userServiceGetUserSession(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login with a kiosk
     * @param {PpeEmployeeKioskLoginRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserServiceApi
     */
    public userServiceKioskLogin(body: PpeEmployeeKioskLoginRequest, options?: AxiosRequestConfig) {
        return UserServiceApiFp(this.configuration).userServiceKioskLogin(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Authenticate a user by email address
     * @param {PpeEmployeePostAuthenticationRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserServiceApi
     */
    public userServicePostAuthentication(body: PpeEmployeePostAuthenticationRequest, options?: AxiosRequestConfig) {
        return UserServiceApiFp(this.configuration).userServicePostAuthentication(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Register fcm token for user
     * @param {PpeEmployeePostFcmTokenRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserServiceApi
     */
    public userServicePostFcm(body: PpeEmployeePostFcmTokenRequest, options?: AxiosRequestConfig) {
        return UserServiceApiFp(this.configuration).userServicePostFcm(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary issue access token to the user by verify the auth code and verification id
     * @param {PpeEmployeePostVerificationRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserServiceApi
     */
    public userServicePostVerification(body: PpeEmployeePostVerificationRequest, options?: AxiosRequestConfig) {
        return UserServiceApiFp(this.configuration).userServicePostVerification(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VendingMachineServiceApi - axios parameter creator
 * @export
 */
export const VendingMachineServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all vending machines that belong to the organization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendingMachineServiceGetAllVendingMachines: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api-e/v1/vending_machines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search a vending machine by either qr_code or uuid
         * @param {PpeEmployeeSearchVendingMachineRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendingMachineServiceSearchVendingMachine: async (body: PpeEmployeeSearchVendingMachineRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('vendingMachineServiceSearchVendingMachine', 'body', body)
            const localVarPath = `/api-e/v1/vending_machines/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VendingMachineServiceApi - functional programming interface
 * @export
 */
export const VendingMachineServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VendingMachineServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all vending machines that belong to the organization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vendingMachineServiceGetAllVendingMachines(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeEmployeeGetAllVendingMachinesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vendingMachineServiceGetAllVendingMachines(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search a vending machine by either qr_code or uuid
         * @param {PpeEmployeeSearchVendingMachineRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vendingMachineServiceSearchVendingMachine(body: PpeEmployeeSearchVendingMachineRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PpeEmployeeSearchVendingMachineResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vendingMachineServiceSearchVendingMachine(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VendingMachineServiceApi - factory interface
 * @export
 */
export const VendingMachineServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VendingMachineServiceApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all vending machines that belong to the organization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendingMachineServiceGetAllVendingMachines(options?: any): AxiosPromise<PpeEmployeeGetAllVendingMachinesResponse> {
            return localVarFp.vendingMachineServiceGetAllVendingMachines(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search a vending machine by either qr_code or uuid
         * @param {PpeEmployeeSearchVendingMachineRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendingMachineServiceSearchVendingMachine(body: PpeEmployeeSearchVendingMachineRequest, options?: any): AxiosPromise<PpeEmployeeSearchVendingMachineResponse> {
            return localVarFp.vendingMachineServiceSearchVendingMachine(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VendingMachineServiceApi - object-oriented interface
 * @export
 * @class VendingMachineServiceApi
 * @extends {BaseAPI}
 */
export class VendingMachineServiceApi extends BaseAPI {
    /**
     * 
     * @summary Get all vending machines that belong to the organization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VendingMachineServiceApi
     */
    public vendingMachineServiceGetAllVendingMachines(options?: AxiosRequestConfig) {
        return VendingMachineServiceApiFp(this.configuration).vendingMachineServiceGetAllVendingMachines(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search a vending machine by either qr_code or uuid
     * @param {PpeEmployeeSearchVendingMachineRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VendingMachineServiceApi
     */
    public vendingMachineServiceSearchVendingMachine(body: PpeEmployeeSearchVendingMachineRequest, options?: AxiosRequestConfig) {
        return VendingMachineServiceApiFp(this.configuration).vendingMachineServiceSearchVendingMachine(body, options).then((request) => request(this.axios, this.basePath));
    }
}


